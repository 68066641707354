import React from 'react';
import { Col, Container } from "react-bootstrap"
import Img from "gatsby-image";

const RecipeBanner = ({ img, title, date }) => {
    return (
        <Container className="text-center d-flex justify-content-center pb-3 under-nav" fluid>
            <Col lg={6}>
                <Img fluid={img.fluid} alt={img.title} key={img.id} />
                <h1 className="pt-3">{title}</h1>
                <h5>{date}</h5>
            </Col>
        </Container>
    )
}

export default RecipeBanner;
