import React from "react"
import { graphql } from "gatsby"
import PageWrapper from "../components/Layout/PageWrapper"
import { FaUtensils } from "react-icons/fa"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import RecipeBanner from "../components/Recipes/RecipeBanner"
import { Col, Container, Row } from "react-bootstrap"
import Img from "gatsby-image";
import SEO from "../components/seo";

const RecipeTemplate = ({ data }) => {
  const {
    title,
    date,
    quantity,
    description,
    ingredientList,
    methodList,
    blogImage,
    images,
    tags,
  } = data.recipe

  return (
    <PageWrapper>
      <SEO title={title} />
      <RecipeBanner img={blogImage} title={title} date={date} />
      <Container fluid className="pb-5">
        <Container fluid className="d-flex justify-content-center">
          <p className="d-flex align-items-center">
            <FaUtensils className="icon" />
            {quantity}
          </p>
        </Container>
        <Row className="d-flex justify-content-center">
          {(tags || []).slice(0, 100).map((t, i) => (
            <AniLink className="pl-3 pr-3" fade key={i} to={`/tags/${t}`}>
              <p>{t}</p>
            </AniLink>
          ))}
        </Row>
        <Container fluid className="d-flex justify-content-center pb-5 pt-5">
          <Col lg={8}>
            <div dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
          </Col>
        </Container>
        <Row className="d-flex justify-content-center">
          <Col lg={3} className="pb-3">
            <Container>
              <h3 className="pb-3">Ingrediënten</h3>
              <div dangerouslySetInnerHTML={{ __html: ingredientList.childContentfulRichText.html }} />
            </Container>
          </Col>
          <Col lg={5} className="pb-3">
            <Container>
              <h3 className="pb-3">Bereidingswijze</h3>
              <div dangerouslySetInnerHTML={{ __html: methodList.childContentfulRichText.html }} />
            </Container>
          </Col>
        </Row>
        <Container className="pt-5 pb-5 d-flex justify-content-center">
          <AniLink fade to="/recipes/" className="btn-blue">
            Terug naar recepten
          </AniLink>
        </Container>
        <Row data-aos="fade-up" className="pl-5 pr-5">
          {images &&
            images.map(({ id, fluid, title }) => {
              return (
                <Col md={6} className="pb-5" key={id}>
                  <Img fluid={fluid} alt={title} />
                </Col>
              )
            })}
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default RecipeTemplate

export const query = graphql`
  query($slug: String!) {
    recipe: contentfulRecipe(slug: { eq: $slug }) {
      title
      date(formatString: "DD MMMM YYYY", locale: "nl")
      quantity
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      ingredientList {
        childContentfulRichText {
          html
        }
      }
      methodList {
        childContentfulRichText {
          html
        }
      }
      blogImage {
        id
        fluid(quality: 100, maxHeight: 750) {
          ...GatsbyContentfulFluid_withWebp
          srcWebp
        }
        title
      }
      images {
        id
        fluid(quality: 100, maxHeight: 750) {
          ...GatsbyContentfulFluid_withWebp
          srcWebp
        }
        title
      }
      tags
    }
  }
`
